import Rails from '@rails/ujs';


require("@rails/ujs").start()

// POST FORM TO RAILS API
$( document ).ready(function() {
  $('#formError').hide();
  $('#formSuccess').hide();

  const overlay = $('#overlay');
  $('.overlay-show').on('click', function() {
    overlay.addClass('show');
  });

  $('#close').on('click', function() {
    overlay.removeClass('show');
  })
});

// Handle form
window.addEventListener("load", () => {
  const element = document.querySelector("#new-customer");
  element.addEventListener("ajax:success", (event) => {
    $('#formSuccess').show();
  });
  element.addEventListener("ajax:error", () => {
    $('#formError').show();
  });
});
